"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const action_1 = require("@src/app/services/action");
class TipcalculatorCtrl {
    constructor($scope, actionService) {
        this.$scope = $scope;
        this.actionService = actionService;
        this.tip = 10;
        this.number_of_people = 1;
    }
    $onInit() {
    }
    submitForm($form) {
        if ($form.$valid && (this.bill != undefined) && (this.tip != undefined) && (this.number_of_people != undefined)) {
            const tip = (this.bill * this.tip) / 100;
            this.result = {
                tip: tip,
                tip_per_person: tip / this.number_of_people,
                total_per_person: (this.bill + tip) / this.number_of_people,
                total: this.bill + tip,
                number_of_people: this.number_of_people
            };
        }
    }
    addTip() {
        if (ng.isNumber(this.tip) && (this.tip >= 0)) {
            if (this.tip < 100) {
                this.tip++;
            }
        }
        else {
            this.tip = 0;
        }
    }
    incTip() {
        if (ng.isNumber(this.tip) && (this.tip > 0)) {
            this.tip--;
        }
        else {
            this.tip = 0;
        }
    }
    addPerson() {
        if (ng.isNumber(this.number_of_people) && (this.number_of_people >= 1)) {
            if (this.number_of_people < 99) {
                this.number_of_people++;
            }
        }
        else {
            this.number_of_people = 1;
        }
    }
    incPerson() {
        if (ng.isNumber(this.number_of_people) && (this.number_of_people > 1)) {
            this.number_of_people--;
        }
        else {
            this.number_of_people = 1;
        }
    }
    clear($form) {
        this.bill = undefined;
        this.tip = 10;
        this.number_of_people = 1;
        $form.$setPristine();
        $form.$setUntouched();
        this.result = undefined;
    }
    copy(id, value) {
        if (this.result) {
            this.actionService.copyFactory(document.getElementById(id), value);
        }
    }
}
TipcalculatorCtrl.$inject = ['$scope', 'ActionService'];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.component('gameTipcalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: TipcalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('tipcalculator/');
    }]);
